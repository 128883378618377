/* a {
    text-decoration: none;
}

.et-hero-tabs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    background: #eee;
    text-align: center;
    padding: 0 2em;
}  
.et-hero-tabs h1 {
    font-size: 2rem;
    margin: 0;
    letter-spacing: 1rem;
}
.et-hero-tabs h3 {
    font-size: 1rem;
    letter-spacing: 0.3rem;
    opacity: 0.6;
}

.et-hero-tabs-container {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 0;
    width: 100%;
    height: 70px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    background: #fff;
    z-index: 10;
}

.et-hero-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    color: rgba(133,0,0,0.8);
    letter-spacing: 0.1rem;
    transition: all 0.5s ease;
    font-size: 0.8rem;
}
.et-hero-tab.active {
    border-bottom: 4px solid rgba(133,0,0,1);
}
.et-hero-tab:hover {
    color: rgba(240, 146, 0, 1);
    background-color: rgba(133,0,0,0.8);
    transition: all 0.5s ease;
}


@media (min-width: 800px) {
.et-hero-tabs h1 {
    font-size: 3rem;
}
.et-hero-tabs h3 {
    font-size: 1rem;
}
}
.et-hero-tab {
    font-size: 1rem;
} */

.et-hero-tabs-container {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 0;
    width: 100%;
     box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    background: #fff;
    z-index: 10;
}

.navbar-brand img {
    max-height:70px;
}
.et-hero-tabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    padding: 0 2em;
    width:100%;
} 
.me-auto {
    width: 100%;
    align-items: center;
}
.et-hero-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    color: rgba(133,0,0,0.8);
    letter-spacing: 0.1rem;
    transition: all 0.5s ease;
    font-size: 1rem;
}
.et-hero-tab.active {
    border-bottom: 4px solid rgba(133,0,0,1);
}
.et-hero-tab:hover {
    color: rgba(240, 146, 0, 1);
    transition: all 0.5s ease;
}