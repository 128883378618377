h2,h3,h4 {
    font-weight:100;
}

p {
    color: black;
    font-family: Arial, Helvetica, sans-serif;
}

p img {
    width:250px;
    float: left;
    margin: 0 25px 25px 0;
}

.cols {
    float: left;
    width: 50%;
    display: inline-block;
}
@media only screen and (max-width: 800px) {
    .left, .right {
        float: none;
        width: 100%;
    }
    p img {
        width: 100%;
    }
}