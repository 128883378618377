html {
  height: 100%;
}

@font-face {
  font-family: Neutra;
  src: url("NeutraText-DemiAlt.woff") format("woff");
}

* {
  box-sizing: border-box;
}

h1,h2,h3,h4 {
  font-weight: 100;
  text-align: center;
}

body {
  min-height: 100%;
  display: grid;
  color: rgba(240, 146, 0, 1);
  font-family: "Neutra", Arial, Helvetica, sans-serif;
}

.container {
    padding: 60px 15px;
    margin-top: 75px;
}
.carousel {
  margin-top: 75px;
}

/* .footer {
  background-image: url("img/border-bottom.png");
  background-repeat: repeat-x;
  background-position: center bottom;
  width: 100%;
  height: 250px;
  position: fixed;
  bottom: 0;
} */

#root {
  display: grid;
}